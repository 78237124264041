import {
  ControllerWithExportsFunction,
  LowCodeControllerConfig,
} from '@wix/blocks-widget-services-types';
import { applyLowCodeRules } from './panel';

export async function createLowCodeController(
  controllerConfig: LowCodeControllerConfig,
): Promise<ControllerWithExportsFunction<Record<string, any>>> {
  return {
    pageReady: async () => {
      await applyLowCodeRules(controllerConfig);
    },
    exports: () => ({}),
  };
}
